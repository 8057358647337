import React from "react"

import Layout from "../layout"
import Seo from "../components/atoms/SEO"
import { PrismicNavigation } from "../layout/PrismicNavigation"
import PrismicFooter from "../layout/PrismicFooter"
import Container from "../components/atoms/Container"
import { withPrismicUnpublishedPreview } from "gatsby-plugin-prismic-previews"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <PrismicNavigation lang={"en-ca"} />
    <Container>
      <h1>Page Not Found</h1>
      <p>Sorry we couldn't find the page you were looking for.</p>
    </Container>
    <PrismicFooter lang={"en-ca"} />
  </Layout>
)

export default withPrismicUnpublishedPreview(NotFoundPage)
